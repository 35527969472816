import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PrivacyP = () => {
    return ( 
        <div className="PrivacyPContainer">
            <Helmet>
                <title>Privacy Policy • Anna Chan~</title>
                <meta property="og:title" content="Privacy Policy • Anna Chan~" />
                <meta name="description" content="Privacy Policy for Anna Chan~." />
                <meta property="og:description" content="Privacy Policy for Anna Chan~." />
                <meta property="og:url" content="annachan.my.id/privacy-policy" />
            </Helmet>
            <div className="PrivacyPBubble">
                <div className="PrivacyPTitle">Privacy Policy</div>
                <div className="PrivacyPSubTitle">Last Updated: 12 Oct. 2024</div>
                <div className="PrivacyPText">
                    <p>
                    This Privacy Policy explains how Anna Chan~ collects, uses, and shares your information when you use the Bot on Discord. By using the Bot, you agree to the collection and use of your information as described in this policy.
                    </p>
                    <br />
                    <p>
                        <b>1. Information We Collect</b>
                    </p>
                    <p>When you use the Bot, we may collect the following types of information:</p>
                    <p>
                    <b>User Data:</b> We collect basic information such as your Discord user ID, username, and server ID. This data is necessary for the Bot to provide its functionalities.
                    <b>Music Playback Data:</b> The Bot may store information about songs played, playlists used, and related music activity to enhance user experience and provide features like music recommendations.
                    <b>Command Usage Data:</b> Information about the commands you use is collected to improve performance and troubleshoot issues.
                    </p>
                    <br />
                    <p>
                        <b>2. How We Use Your Information</b>
                    </p>
                    <p>We use the information collected for the following purposes:</p>
                    <p>
                    To operate and maintain the Bot.
To personalize the music experience by providing recommendations or customized features.
                    To improve the Bot's functionality and fix any issues or bugs.
                    To monitor usage patterns and maintain server performance.
                    </p>
                    <br />
                    <p>
                        <b>3. Data Storage and Retention</b>
                    </p>
                    <p>
                    Data is stored in a MongoDB database. The database is secured to prevent
                    The Bot stores user data and usage logs securely on a server. We do not store sensitive information like passwords or private messages.
                    We retain your data only as long as necessary to provide services or comply with legal obligations.
                    Once you remove the Bot from your servers, we will delete all associated data unless required by law to retain it.
                    </p>
                    <br />
                    <p>
                        <b>4. Data Sharing</b>
                    </p>
                    <p>
                    We do not share or sell your personal information to third parties. However, the Bot may use third-party services such as music APIs (e.g., Spotify, Genius) to fetch and deliver music content. These services may collect their own data in accordance with their privacy policies. 
                    </p>
                    <br />
                    <p>
                        <b>5. Third-Party Links and Services</b>
                    </p>
                    <p>
                    The Bot may link to third-party websites or services, such as music platforms or lyrics providers. These third-party services have their own privacy policies, and we are not responsible for how they handle your data.
                    </p>
                    <br />
                    <p>
                        <b>6. Your Data Rights</b>
                    </p>
                    <p>You have the following rights regarding your data:</p>
                    <p>
                    <b>Access:</b> You may request access to the information we hold about you.
Correction: If you believe your information is incorrect, you may request that we correct or update it.
                    <b>Deletion:</b> You may request that we delete your data by removing the Bot from your server or contacting us directly.
                    <b>Objection:</b> You can object to certain data processing activities, such as marketing or data analytics.
                    </p>
                    <br />
                    <p>
                    <b>7. Security</b>
                    </p>
                    <p>
                    We take reasonable steps to protect your information from unauthorized access, alteration, or disclosure. However, no method of data transmission or storage is 100% secure, so we cannot guarantee absolute security.
                    </p>
                    <br />
                    <p>
                    <b>8. Children's Privacy</b>
                    </p>
                    <p>
                    The Bot is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe a child under 13 is using the Bot, please contact us, and we will take steps to remove their data.
                    </p>
                    <br />
                    <p>
                    <b>9. Changes to This Policy</b>
                    </p>
                    <p>
                    We may update this Privacy Policy from time to time to reflect changes in the Bot’s functionality or applicable laws. We will notify users of significant changes via announcements or in-app notifications. Continued use of the Bot after any changes constitutes acceptance of the updated policy.
                    </p>
                    <br />
                    <p>
                        <b>10. Contact Us</b>
                    </p>
                    <p>
                    If you have any questions or concerns about this Privacy Policy or your data, you can reach us at <Link to="/discord" className='GenericLinkDeco' id="PrivacyPLink">Support Server</Link>
                        . For more information check the Discord Terms Of Service.
                    </p>
                </div>
            </div>
        </div>
     );
}

export default PrivacyP;