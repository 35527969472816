import { useState } from "react";
import { Helmet } from 'react-helmet-async';

const Commands = () => {

    // Filters List
    // all, confessions, moderation, fun, emotes, utility
    const [filters] = useState([
        {filterName: 'All', filterID:"all", id: 1},
        {filterName: 'Music', filterID:"music", id: 2},
        {filterName: 'Filters', filterID:"filter", id: 3},
        {filterName: 'Playlist', filterID:"playlist", id: 4},
        {filterName: 'Utilities', filterID:"utility", id: 5}
    ])

    // Commands List
    const [commands] = useState([
        {commandName: 'am!help', commandDesc:"A full list of Anna Chan's commands", commandUsage:"am!help", filter:"utility", id: 1},
        {commandName: 'am!autoplay', commandDesc:"Get track recommendations from the music being played.", commandUsage:"am!autoplay", filter:"music", id: 2},
        {commandName: 'am!clear', commandDesc:"Clears all track from the queue.", commandUsage:"am!clear", filter:"music", id: 3},
        {commandName: 'am!forceskip', commandDesc:"Force skips the current track.", commandUsage:"am!forceskip", filter:"music", id: 4},
        {commandName: 'am!join', commandDesc:"Joins the voice channel you are in.", commandUsage:"am!join", filter:"music", id: 5},
        {commandName: 'am!leave', commandDesc:"Leaves the voice channel.", commandUsage:"am!leave", filter:"music", id: 6},
        {commandName: 'am!loop', commandDesc:"Loop the current track.", commandUsage:"am!loop", filter:"music", id: 7},
        {commandName: 'am!lyrics', commandDesc:"Get the lyrics of the current track.", commandUsage:"am!lyrics", filter:"music", id: 8},
        {commandName: 'am!nowplaying', commandDesc:"Shows the current track.", commandUsage:"am!nowplaying", filter:"music", id: 9},
        {commandName: 'am!pause', commandDesc:"Pauses the current track.", commandUsage:"am!pause", filter:"music", id: 10},
        {commandName: 'am!play', commandDesc:"Plays a track using title or url.", commandUsage:"am!play <title/url>", filter:"music", id: 11},
        {commandName: 'am!queue', commandDesc:"Shows the current queue.", commandUsage:"am!queue", filter:"music", id: 12},
        {commandName: 'am!remove', commandDesc:"Removes a track from the queue.", commandUsage:"am!remove <number of song in queue>", filter:"music", id: 13},
        {commandName: 'am!resume', commandDesc:"Resumes the current track.", commandUsage:"am!resume", filter:"music", id: 14},
        {commandName: 'am!search', commandDesc:"Search for a track using title.", commandUsage:"am!search <title>", filter:"music", id: 15},
        {commandName: 'am!seek', commandDesc:"Seek to a specific time in the current track.", commandUsage:"am!seek <10s || 10m || 10h>", filter:"music", id: 16},
        {commandName: 'am!shuffle', commandDesc:"Shuffles the current queue.", commandUsage:"am!shuffle", filter:"am!shuffle", id: 17},
        {commandName: 'am!skip', commandDesc:"Skips the current track.", commandUsage:"am!skip", filter:"music", id: 18},
        {commandName: 'am!skipto', commandDesc:"Skips to a specific track in the queue.", commandUsage:"am!skipto <number of song in queue>", filter:"music", id: 19},
        {commandName: 'am!stop', commandDesc:"Stops the current track.", commandUsage:"am!stop", filter:"music", id: 20},
        {commandName: 'am!volume', commandDesc:"Change the volume of the current track.", commandUsage:"am!volume <0-100>", filter:"music", id: 21},
        {commandName: 'am!8d', commandDesc:"Enables 8D audio mode", commandUsage:"am!8d", filter:"filter", id: 22},
        {commandName: 'am!bassboost', commandName:"Enables bassboost audio mode", commandUsage:"am!bassboost", filter:"filter", id: 23},
        {commandName: 'am!bass', commandDesc:"Enables bass audio mode", commandUsage:"am!bass", filter:"filter", id: 24},
        {commandName: 'am!earrape', commandDesc:"Enables earrape audio mode", commandUsage:"am!earrape", filter:"filter", id: 25},
        {commandName: 'am!electronic', commandDesc:"Enables electronic audio mode", commandUsage:"am!electronic", filter:"filter", id: 26},
        {commandName: 'am!equalizer', commandDesc:"Enables equalizer audio mode", commandUsage:"am!equalizer", filter:"filter", id: 27},
        {commandName: 'am!karaoke', commandDesc:"Enables karaoke audio mode", commandUsage:"am!karaoke", filter:"filter", id: 28},
        {commandName: 'am!nightcore', commandDesc:"Enables nightcore audio mode", commandUsage:"am!nightcore", filter:"filter", id: 29},
        {commandName: 'am!party', commandDesc:"Enables party audio mode", commandUsage:"am!party", filter:"filter", id: 30},
        {commandName: 'am!pop', commandDesc:"Enables pop audio mode", commandUsage:"am!pop", filter:"filter", id: 31},
        {commandName: 'am!radio', commandDesc:"Enables radio audio mode", commandUsage:"am!radio", filter:"filter", id: 32},
        {commandName: 'am!soft', commandDesc:"Enables soft audio mode", commandUsage:"am!soft", filter:"filter", id: 33},
        {commandName: 'am!speed', commandDesc:"Enables speed audio mode", commandUsage:"am!speed", filter:"filter", id: 34},
        {commandName: 'am!treblebass', commandDesc:"Enables treblebass audio mode", commandUsage:"am!treblebass", filter:"filter", id: 35},
        {commandName: 'am!vaporwave', commandDesc:"Enables vaporwave audio mode", commandUsage:"am!vaporwave", filter:"filter", id: 36},
        {commandName: 'am!create', commandDesc:"Create your own playlist.", commandUsage:"am!create <playlist name>", filter:"playlist", id: 37},
        {commandName: 'am!delete', commandDesc:"Delete your own playlist.", commandUsage:"am!delete <playlist name>", filter:"playlist", id: 38},
        {commandName: 'am!list', commandDesc:"List all your playlist.", commandUsage:"am!list", filter:"playlist", id: 39},
        {commandName: 'am!load', commandDesc:"Load your own playlist.", commandUsage:"am!load <playlist name>", filter:"playlist", id: 40},
        {commandName: 'am!removetrack', commandDesc:"Remove a track from your playlist.", commandUsage:"am!removetrack <playlist name> <track number>", filter:"playlist", id: 41},
        {commandName: 'am!savecurrent', commandDesc:"Save the current track to your playlist.", commandUsage:"am!savecurrent <playlist name>", filter:"playlist", id: 42},
        {commandName: 'am!savequeue', commandDesc:"Save the current queue to your playlist.", commandUsage:"am!savequeue <playlist name>", filter:"playlist", id: 43},
        {commandName: 'am!invite', commandDesc:"Get link to invite Anna Chan~ to your server.", commandUsahe:"am!invite", filter:"utility", id: 44},
        {commandName: 'am!prefix', commandDesc:"Shows list of prefixes.", commandUsage:"am!prefix", filter:"utility", id: 45},
        {commandName: 'am!ping', commandDesc:"Shows Anna Chan's latency.", commandUsage:"am!ping", filter:"utility", id: 46},
        {commandName: 'am!setprefix', commandDesc:"Set a custom prefix for your server.", commandUsage:"am!prefix <prefix>", filter:"utility", id: 47},
        {commandName: 'am!selfprefix', commandDesc:"Set a custom prefix for yourself.", commandUsage:"am!selfprefix <new prefix/reset>", filter:"utility", id: 48},
        {commandName: 'am!status', commandDesc:"Shows Anna Chan's status.", commandUsage:"am!status", filter:"utility", id: 49},
        {commandName: 'am!support', commandDesc:"Get link to join Anna Chan's support server.", commandUsage:"am!support", filter:"utility", id: 50}
    ])

    //Button Handlers
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('all');

    const handleClickCommand = (commandId) => {
        setSelectedCommand(commandId === selectedCommand ? null : commandId);
    };
    const handleClickFilter = (filter) => {
        setSelectedFilter(filter === selectedFilter ? 'all' : filter);
    };

    return ( 
        <div className="CommandsContent">
            <Helmet>
                <title>Commands • Anna Chan~</title>
                <meta property="og:title" content="Commands • Anna Chan~" />
                <meta name="description" content="The commands list for Anna Chan~." />
                <meta property="og:description" content="The commands list for Anna Chan~." />
                <meta property="og:url" content="annachan.my.id/commands" />
            </Helmet>
            <div className="CommandsLayoutContainer">
                <div className="CommandBoxContainer">
                <div className="CommandAltAnimationHandler"> 
                    <div className="CommandsSectionTitle">Commands</div>
                    <div className="CommandsFilterContainer">
                    {filters.map((filter) => (
                        <div className="FilterButton" id={`${filter.filterID === selectedFilter ? "selected" : "" }`} onClick={() => handleClickFilter(filter.filterID)} key={filter.id}>
                            <div className="FilterTitle">{filter.filterName}</div>
                        </div>
                    ))}
                </div>
                </div>
                <div className="CommandListContainer">
                    {commands.map((command, index) => (
                        <div className="CommandBubbleAnimationHandler" style={{ animationDelay: `${25 * index}ms` }} key={command.id}>
                            <div className={`${command.id === selectedCommand ? "CommandBubbleExpanded" : "CommandBubble" }`} id={`${command.filter === selectedFilter || 'all' === selectedFilter ? "" : "inactive" }`} key={command.id} onClick={() => handleClickCommand(command.id)}>
                                <div className="CommandBubbleInsideContainer">
                                    <div className="CommandBubbleNonExpandedContainer">
                                        <div className="CommandBubbleTitleContainer">
                                            <div className="CommandBubbleTitle">{command.commandName}</div>
                                            <div className="CommandBubbleExpandArrow" id={`menuButton_${command.id === selectedCommand ? 'active' : 'inactive'}`}>
                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdownIcon" id={`dropdownIcon_${command.id === selectedCommand ? 'active' : 'inactive'}`}><path d="M7 14.5l5-5 5 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </div>
                                        </div>
                                        <div className="CommandBubbleDesc">{command.commandDesc}</div>
                                    </div>
                                    <div className="CommandBubbleExpandedContainer" id={`${command.id === selectedCommand ? "" : "inactive" }`} >
                                        <div className="CommandBubbleField">
                                            <div className="CommandBubbleFieldTitle">Usage</div>
                                            <div className="CommandBubbleFieldSubTitleContainer" id="usage">
                                                <div className="CommandBubbleFieldSubTitle">
                                                    {command.commandUsage} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="CommandBubbleField">
                                            <div className="CommandBubbleFieldTitle">Category</div>
                                            <div className="CommandBubbleFieldSubTitle">{command.filter}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    ))}
                </div>    
            </div>
            </div>
        </div>
     );
}

export default Commands;