import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return ( 
        <div className="HomeContent">
            <Helmet>
                <title>Anna Chan~</title>
                <meta property="og:title" content="Anna Chan~" />
                <meta name="description" content="The best free Discord Music bot." />
                <meta property="og:description" content="The best free Discord Music bot." />
                <meta property="og:url" content="https://annachan.my.id" />
            </Helmet>
            <div className="LayoutContainer">
                {/* Info Section */}
                <div className="InfoBubble">
                    <div className="InfoColLeft">
                        <div className="InfoContent">
                            <div className="AltBoldText">Anna Chan~</div>
                            <div className="AltText">
                                Anna Chan~ is a powerful Discord music bot that delivers high-quality music streaming and a seamless listening experience directly to your server.
                            </div>
                            <div className="infoButtonContainer">
                                <Link to="/invite" className="GenericLinkDeco">
                                    <div className="infoButton" id="InfoMainButton">Invite me</div>
                                </Link>
                                <Link to="/commands" className="GenericLinkDeco">
                                    <div className="infoButton" id="InfoAltButton">Commands</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="InfoColRight">
                        <div className="InfoImg">
                            <img src="./img/Logos/AnnaBlueBorder.png" alt="Anna Logo" width="150" height="150" />
                        </div>
                    </div>
                </div>

                {/* Divider */}
                <div className="InfoDividerHR"><hr /></div>

                {/* Features Section */}
                <div className="FeatureTitleContainer">
                    <div className="FeatureTitleText">Features:</div>
                </div>
                <div className="FeatureBubbleContainer">
                    {/* Feature 1 */}
                    <div className="FeatureBubble" id="first">
                        <div className="FeatureColLeft">
                            <div className="FTextContainer">
                                <div className="FTitle" id="LongBoi">Play a Music</div>
                                <div className="FSubTitle">Play music using the title or URL of your desired platform.</div>
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className="FPic">
                                <img src="./img/FeatureScreenshots/PlaySS.png" alt="Play Screenshot" width="280" height="310" />
                            </div>
                        </div>
                    </div>

                    {/* Feature 2 */}
                    <div className="FeatureBubble" id="second">
                        <div className="FeatureColLeft">
                            <div className="FPic" id="CL">
                                <img src="./img/FeatureScreenshots/PlaylistSS.png" alt="Playlist Screenshot" width="280" height="168" />
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className="FTextContainer" id="CL">
                                <div className="FTitle">Own Playlist</div>
                                <div className="FSubTitle">Create and grab the currently playing music to your playlist.</div>
                            </div>
                        </div>
                    </div>

                    {/* Feature 3 */}
                    <div className="FeatureBubble" id="third">
                        <div className="FeatureColLeft">
                            <div className="FTextContainer" id="GE">
                                <div className="FTitle">Personal Prefix</div>
                                <div className="FSubTitle">Create your own prefix and use it on multiple servers.</div>
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className="FPic" id="GE">
                                <img src="./img/FeatureScreenshots/PrefixSS.png" alt="Prefix Screenshot" width="280" height="210" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Spacer */}
                <div className="EndOfPageSpacer" />
            </div>
        </div>
    );
}

export default Home;